import './bootstrap.js';
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import { async } from 'regenerator-runtime';
import './styles/app.scss';

import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Table from '@editorjs/table';
import { Dropdown } from 'bootstrap';


// start the Stimulus application
// import './bootstrap';

document.addEventListener('DOMContentLoaded', async () => {
    const app = new App();
});

class App {
    constructor() {
        
    }

    enableDropdowne() {
        const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
        const dropdownList = [...dropdownElementList].map(dropdownToggleEl => new Dropdown(dropdownToggleEl))
    }
}